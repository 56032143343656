<template>
<div>
  <div v-if="!auth.authenticated && authstate.loading === false" class="container">
            <!-- <div> Auth state? : {{authstate.loading}} </div> -->
          <div> <p>Login with your Discord Credentials to view the "HeatMapFX Levels"</p> </div>
          <div> <p>If you are a Paid Member, login for basic access</p> </div>
          <div class="btn btn-dark btn-lg active" v-on:click="auth.loginWithRedirect()" :disabled="auth.authenticated">Login</div>
          <hr>
        <!-- <div> Auth Level? : {{authLevel}} </div> -->
        <div > <p>If you are NOT a Paid Member, start your trial here:</p></div>
        <div>
          <a href="https://launchpass.com/heatmapfx/paid-members" class="btn btn-secondary btn-lg active" role="button">
          Start your HeatMapFx Paid Trial Here</a>
        </div>
        <hr>
 </div>
        <div v-if="authLevel === 0">
        <div > This section is for paid members only.  To access the paid data please sign up here:</div>
        <div class="row">
          <a href="https://launchpass.com/heatmapfx/paid-members" class="btn btn-secondary btn-lg active" role="button">
          Start your HeatMapFx Paid Trial Here</a>
        </div>
        </div>
    <div v-if="auth.authenticated && authLevel === 1 || authLevel === 2 || authLevel === 3 || authLevel === 4">
      <!-- <div> Auth state? : {{authstate}} </div> -->
      <div class="row selectName"> Select a TimeFrame </div>
         <div class="row selectBoxPad">
            <select class="select" v-model="timex">
               <option v-for="item in timefrs" v-bind:key="item.timefr" :value="item.timefr">{{item.timefr}}</option>
              </select>                      
             </div>
    <!-- <div v-for="item in iplot" v-bind:key="item.index">  -->
      <div v-if="timex === '20 Minutes'">
        <div>{{selectedTf}}</div>
        <div v-for="item in iplot" v-bind:key="item.index">    
            <img class="img-fluid" v-bind:src="'data:image/jpeg;base64,'+item" />
        </div>
      </div>
      <div v-if="timex === 'Daily'">
         <div>{{selectedTf}}</div>
        <div v-for="item in iDailyPlot" v-bind:key="item.index">    
            <img class="img-fluid" v-bind:src="'data:image/jpeg;base64,'+item" />
        </div>
      </div>
</div>
</div>
</template>
<script>
import axios from 'axios'
import { AuthenticationState } from 'vue-auth0-plugin';
export default {
  inject: ['auth'],
  data: function() {
    return {
          timefrs:[ 
                    {timefr: "20 Minutes"},
                    {timefr: "Daily"}
                  ],
          timex:{},
          iplot:null,
          iDailyPlot:null,
          ibetatesters: null,
          ispecialtesters: null,
          itboneUsers: null,
          ipaidMembers: null,
          ipremiumMembers: null,
          authstate: AuthenticationState,
          isinbeta: false,
          isinspecial: false,
          isinTbone: false,
          isinpaid: false,
          isinprem: false

    } 
  },
  methods: {
    plot: function(){
      axios.get(this.$apihostname+'/imgs')
      .then(response => response.data)
      .then(data => {
         this.iplot = data
        // console.log(data)
        // console.log(data[1][0])
      })
    },
    DailyPlot: function(){
      axios.get(this.$apihostname+'/DailyImgs')
      .then(response => response.data)
      .then(data => {
         this.iDailyPlot = data
        // console.log(data)
        // console.log(data[1][0])
      })
    },
    betatesters: function(){
      axios.get(this.$apihostname+'/np/betatesters')
      .then(response => response.data)
      .then(data => {
        this.ibetatesters = data
        this.isinbeta = data.includes(this.auth.user?.sub.slice(15,33))
        })
      },
    specialtesters: function(){
      axios.get(this.$apihostname+'/np/specialtesting')
      .then(response => response.data)
      .then(data => {
        this.ispecialtesters = data
        this.isinspecial = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    paidMembers: function(){    /// Paid Basic Members
      axios.get(this.$apihostname+'/np/paidMembers')
      .then(response => response.data)
      .then(data => {
        this.ipaidMembers = data
        this.isinpaid = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    premiumMembers: function(){    /// Premium Members
      axios.get(this.$apihostname+'/np/premiumMembers')
      .then(response => response.data)
      .then(data => {
        this.ipremiumMembers = data
        this.isinprem = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    tboneUsers: function(){    /// tbone users
      axios.get(this.$apihostname+'/np/tboneUsers')
      .then(response => response.data)
      .then(data => {
        this.itboneUsers = data
        this.isinTbone = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
  },
  created(){
    this.plot()
    this.DailyPlot()
    this.betatesters()
    this.specialtesters()
    this.paidMembers()
    this.premiumMembers()
    this.tboneUsers()
  },
    mounted(){
    const imgChartSelected = localStorage.getItem('imgChartSelected')
    this.timex = imgChartSelected ? imgChartSelected : {}
    this.interval = setInterval(() => {
        this.plot()
        this.DailyPlot()
        this.betatesters()
    this.specialtesters()
    this.paidMembers()
    this.premiumMembers()
    this.tboneUsers()
    }, 30000 );
  },
   watch: {
    timex: {
      deep: true,
      handler(timex) {
        localStorage.setItem('imgChartSelected', timex)
      }
    }
  },
  computed: {
     selectedTf: function(){
      // console.log(this.timex)
      return this.timex
      // return _(this.ioobBuys).groupBy(this.ioobBuys => this.ioobBuys.dir)
        },
        authLevel: function() {
          //  const authp = injectAuth();
            if(
               this.isinspecial === true                
               ){
             return 4
               }
            else if(
               this.isinbeta === true
               ){
              return 3
            }
            else if(
               this.isinprem === true        
               ){
              return 2
            }
            else if(
               this.isinpaid  === true      
               ){
              return 1
            }
            else if(this.isinTbone === true || this.isinpaid === true){
              return 0
            }
            else return 5
        }
  }
};
</script>
<style scoped>
a {
    text-decoration: none;
    color: #4aa9e9;
}
.select {
  width: 300px;
  /* display: inline-block; */
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(204, 204, 204);
  transition: all .5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  height: 100%;
  text-align: left;
  cursor: pointer;
  display: block;
  padding: 5px;
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all .3s ease-in-out;
  float: right;
  line-height: 20px;
  padding-left: 0.5rem;
}
.select:hover {
    box-shadow: 0 0 4px rgb(204, 204, 204)
}
.selectName{
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: center;
    font-size: 12px;
    /* display: inline-block; */
}
.selectBoxPad{
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

</style>
